const routes = {
  embed: {
    listUnits: (orgId: string) => `/embed/${orgId}/units`,
    listCities: (orgId: string) => `/embed/${orgId}/cities`,
    listZipCodes: (orgId: string) => `/embed/${orgId}/zipcodes`,
    unitDetails: (orgId: string, unitId: number) =>
      `/embed/${orgId}/unit/${unitId}`,
    contact: (orgId: string, unitId: number) =>
      `/embed/${orgId}/unit/${unitId}/contact`,
    organization: (orgId: string) => `/embed/organization/${orgId}`,
  },
};

export default routes;
