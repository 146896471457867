import { UnitImage } from "models/UnitImage";
import { RootStore } from "stores";
import { ChildStore } from "stores/ChildStore";

export class UnitImageStore extends ChildStore<UnitImage> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    UnitImage._store = this;
  }

  reset() {
    this.entities.clear();
  }
}
