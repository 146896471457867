import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { UnitModel } from "../../models/UnitModel";
import { RootStore } from "../RootStore";
import { PaginatedModelList, ModelItem } from "@devslane/mobx-entity-manager";
import ApiRoutes from "routes/ApiRoutes";
import { unitService } from "services/entities/unitService";

export class UnitStore extends ChildStore<UnitModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    UnitModel._store = this;
  }

  @observable units: PaginatedModelList<UnitModel> =
    new PaginatedModelList<UnitModel>(UnitModel);

  @observable unitItem = new ModelItem<UnitModel>(UnitModel);

  @observable isFetchingCities: boolean = false;

  @observable cities: { name: string }[] = [];

  @observable isFetchingZipCodes: boolean = false;

  @observable zipCodes: string[] = [];

  reset() {
    this.units = new PaginatedModelList<UnitModel>(UnitModel);
    this.cities = [];
    this.isFetchingCities = false;
    this.zipCodes = [];
    this.isFetchingZipCodes = false;
    this.entities.clear();
  }

  @action
  loadUnits(orgId: string, params?: { [key: string]: any }) {
    return this.units.load(ApiRoutes.embed.listUnits(orgId), params);
  }

  @action async fetchUnitFromId(orgId: string, unitId: number) {
    return this.unitItem.load(ApiRoutes.embed.unitDetails(orgId, unitId));
  }

  @action
  async fetchCities(orgId: string) {
    try {
      if (this.cities.length <= 0) {
        this.isFetchingCities = true;
        const response = await unitService.loadCities(orgId);
        this.cities.push(...response);
        this.isFetchingCities = false;
      }
    } catch (e) {
      this.isFetchingCities = false;
      throw e;
    }
  }

  @action
  async fetchZipCodes(orgId: string) {
    try {
      if (this.zipCodes.length <= 0) {
        this.isFetchingZipCodes = true;
        const response = await unitService.loadZipCodes(orgId);
        this.zipCodes.push(...response);
        this.isFetchingZipCodes = false;
      }
    } catch (e) {
      this.isFetchingZipCodes = false;
      throw e;
    }
  }

  @action
  async patriotContactUs(orgId: string, unitId: number, body: { [key: string]: any }) {
    return await unitService.patriotContactUs(orgId, unitId, body);
  }
}
