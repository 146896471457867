import { observable } from "mobx";
import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { OrganizationStore } from "stores/entities/OrganizationStore";

export class Organization extends EntityBaseModel {
  static _store: OrganizationStore;

  @observable owner_id!: number;

  @observable name!: string;

  @observable logo_url!: string;
}

