import { ModelItem } from "@devslane/mobx-entity-manager";
import { action, observable } from "mobx";
import { Organization } from "models/Organisation";
import ApiRoutes from "routes/ApiRoutes";
import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";

export class OrganizationStore extends ChildStore<Organization> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    Organization._store = this;
  }

  @observable organizationItem = new ModelItem<Organization>(Organization);

  @action async fetchOrgFromId(orgId: string) {
    return this.organizationItem.load(ApiRoutes.embed.organization(orgId));
  }

  reset() {
    this.organizationItem = new ModelItem<Organization>(Organization);
    this.entities.clear();
  }
}
