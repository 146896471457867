import {
  EntityBaseModel,
  ModelItem,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { UnitStore } from "stores/entities/UnitStore";
import { Building } from "./BuildingModel";
import { UnitImage } from "./UnitImage";

export class UnitModel extends EntityBaseModel {
  static _store: UnitStore;

  @observable
  building_id!: number;

  @observable
  status_id!: number;

  @observable
  name!: string;

  @observable
  address_line_1!: string;

  @observable
  address_line_2!: string;

  @observable state_code!: string;

  @observable
  city_name!: string;

  @observable
  zip_code!: string;

  @observable
  status!: string;

  @observable
  unit_full_address!: string;

  @observable
  monthly_rent!: number;

  @observable
  security_deposit!: number;

  @observable
  bedrooms!: number;

  @observable
  bathrooms!: number;

  @observable
  cats_allowed!: boolean;

  @observable
  dogs_allowed!: boolean;

  @observable
  building = new ModelItem<Building>(Building);

  @observable
  unit_images = new ModelList<UnitImage>(UnitImage);

  @observable
  marketing_description!: string;

  @observable
  marketing_title!: string;

  @observable
  latitude!: number;

  @observable
  longitude!: number;

  get fullAddress() {
    if (this.address_line_1 && this.address_line_2) {
      return this.address_line_1 + " " + this.address_line_2;
    }
    return this.address_line_1;
  }

  get completeName() {
    return `${this.name}, ${this.address_line_1}${
      this.address_line_2 ? ` - ${this.address_line_2}` : ""
    }, ${this.city_name}, ${this.state_code}, ${this.zip_code}`;
  }
}
