import { EntityBaseModel, ModelList } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { BuildingStore } from "stores/entities/BuildingStore";
import { BuildingImage } from "./BuildingImage";

export enum BuildingType {
  SINGLE_UNIT = "single_unit",
  MULTI_UNIT = "multi_unit",
}

export class Building extends EntityBaseModel {
  static _store: BuildingStore;

  @observable address_line_1!: string;

  @observable address_line_2!: string;

  @observable city_id!: number;

  @observable latitude!: number;

  @observable longitude!: number;

  @observable name!: string;

  @observable organisation_id!: number;

  @observable state_id!: number;

  @observable type!: BuildingType;

  @observable zip_code!: string;

  @observable building_images = new ModelList<BuildingImage>(BuildingImage);
}
