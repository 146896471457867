import React from "react";

export const LazyLoadedListingPage = React.lazy(
  () => import("../pages/EmbedIframe")
);
export const LazyLoadedDetailPage = React.lazy(
  () => import("../pages/UnitDetail")
);
export const LazyLoadedNotFoundPage = React.lazy(
  () => import("../pages/NotFound")
);
