import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-slidedown/lib/slidedown.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from "react-router-dom";
import { ORG_ID_6_ENCRYPTED } from "utils/constants";
import {
  LazyLoadedDetailPage,
  LazyLoadedListingPage,
  LazyLoadedNotFoundPage,
} from "routes";
import { getOrgUrl } from "routes/link.helper";
import { AppRoutes } from "routes/routes";

export default function App() {
  return (
    <div className="w-screen h-screen overflow-x-hidden font-nunito-sans bg-secondary">
      <Switch>
        <Route path={AppRoutes.unitDetailUrl} exact>
          <LazyLoadedDetailPage />
        </Route>
        <Route path={AppRoutes.orgUrl} exact>
          <LazyLoadedListingPage />
        </Route>
        <Route path="/" exact>
          <Redirect to={getOrgUrl(ORG_ID_6_ENCRYPTED || "")} />
        </Route>
        <Route>
          <LazyLoadedNotFoundPage />
        </Route>
      </Switch>
      <ToastContainer />
    </div>
  );
}
