import { Building } from "models/BuildingModel";
import { RootStore } from "stores";
import { ChildStore } from "stores/ChildStore";

export class BuildingStore extends ChildStore<Building> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    Building._store = this;
  }

  reset() {
    this.entities.clear();
  }
}
