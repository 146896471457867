import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { BuildingImageStore } from "stores/entities/BuildingImageStore";

export class BuildingImage extends EntityBaseModel {
  static _store: BuildingImageStore;

  @observable building_id!: number;

  @observable url!: string;
}
