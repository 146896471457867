import { ChildStore } from "../ChildStore";
import { RootStore } from "../RootStore";
import { State } from "models/State";

export class StateStore extends ChildStore<State> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    State._store = this;
  }

  reset() {
    this.entities.clear();
  }
}
