import { REACT_APP_ORG_ID_6, REACT_APP_ORG_ID_24 } from "./secrets";

export enum SORT_BY_VALUES {
  MOST_RECENT = "most_recent",
  LOW_TO_HIGH = "low_to_high",
  HIGH_TO_LOW = "high_to_low",
  BEDROOMS = "bedrooms",
}

export const SORT_BY = [
  { title: "Most Recent", value: SORT_BY_VALUES.MOST_RECENT },
  { title: "Rent(Low to High)", value: SORT_BY_VALUES.LOW_TO_HIGH },
  { title: "Rent(High to Low)", value: SORT_BY_VALUES.HIGH_TO_LOW },
  { title: "Bedrooms", value: SORT_BY_VALUES.BEDROOMS },
];

export enum PETS_ALLOWED_VALUES {
  YES = "yes",
  NO = "no",
  NONE = "none",
}

export const PETS_ALLOWED = [
  { title: "Yes", value: PETS_ALLOWED_VALUES.YES },
  { title: "No", value: PETS_ALLOWED_VALUES.NO },
  { title: "N/A", value: PETS_ALLOWED_VALUES.NONE },
];

export const USA_BOUNDS = {
  west: -171.791110603,
  south: 18.91619,
  east: -66.96466,
  north: 71.3577635769,
};

export const ORG_ID_6_ENCRYPTED = REACT_APP_ORG_ID_6;
export const ORG_ID_24_ENCRYPTED = REACT_APP_ORG_ID_24;

export const IMAGE_PLACEHOLDER =
  "https://mymatrix-assets.s3.amazonaws.com/matrix-logo-large.png";
