import { ContextStatic } from "@devslane/mobx-entity-manager";
import ApiRoutes from "routes/ApiRoutes";

class UnitService {
  static getInstance(): UnitService {
    return new UnitService();
  }

  async loadCities(orgId: string): Promise<{ name: string }[]> {
    return await ContextStatic.default.apiService.get(
      ApiRoutes.embed.listCities(orgId)
    );
  }

  async loadZipCodes(orgId: string): Promise<string[]> {
    return await ContextStatic.default.apiService.get(
      ApiRoutes.embed.listZipCodes(orgId)
    );
  }

  async patriotContactUs(
    orgId: string,
    unitId: number,
    body: { [key: string]: any }
  ): Promise<void> {
    return await ContextStatic.default.apiService.post(
      ApiRoutes.embed.contact(orgId, unitId),
      body
    );
  }
}

export const unitService = UnitService.getInstance();
