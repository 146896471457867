import { AppRoutes } from "./routes";

export const getDetailUrl = (orgId: string, unitId: number) => {
  return AppRoutes.unitDetailUrl
    .replaceAll(":orgId", `${orgId}`)
    .replaceAll(":unitId", `${unitId}`);
};

export const getOrgUrl = (orgId: string) => {
  return AppRoutes.orgUrl.replaceAll(":orgId", orgId);
};
