import { BuildingImage } from "models/BuildingImage";
import { RootStore } from "stores";
import { ChildStore } from "stores/ChildStore";

export class BuildingImageStore extends ChildStore<BuildingImage> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    BuildingImage._store = this;
  }

  reset() {
    this.entities.clear();
  }
}
