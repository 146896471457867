import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import envConfig from "./Env.config";
import { Context } from "stores/Context";
import { StorageService } from "services/StorageService";
import { ToastService } from "services/ToastService";
import { AppContext } from "App.context";
import { BrowserRouter } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";

Context.boot({
  storageService: StorageService.getInstance(),
  baseUrl: envConfig.BACKEND_URL as string,
  toastService: ToastService.getInstance(),
});

AppContext.boot();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="h-screen w-screen bg-secondary flex items-center justify-center">
            <BiLoaderAlt className="animate-spin h-10 w-10 text-orange" />
          </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
