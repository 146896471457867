import { action } from "mobx";
import { ChildStore } from "./ChildStore";
import { Context } from "./Context";
import { BuildingImageStore } from "./entities/BuildingImageStore";
import { BuildingStore } from "./entities/BuildingStore";
import { UnitImageStore } from "./entities/UnitImageStore";
import { StateStore } from "./entities/StateStore";
import { UnitStore } from "./entities/UnitStore";
import { OrganizationStore } from "./entities/OrganizationStore";

export class RootStore {
  private static _instance: RootStore;

  unitStore: UnitStore;

  buildingStore: BuildingStore;

  buildingImageStore: BuildingImageStore;

  unitImageStore: UnitImageStore;

  stateStore: StateStore;

  organizationStore: OrganizationStore;

  private constructor() {
    // We will pass the instance of RootStore in ChildStore for better accessibility of siblings
    this.unitStore = new UnitStore(this);
    this.buildingStore = new BuildingStore(this);
    this.buildingImageStore = new BuildingImageStore(this);
    this.unitImageStore = new UnitImageStore(this);
    this.stateStore = new StateStore(this);
    this.organizationStore = new OrganizationStore(this);
  }

  static getInstance(): RootStore {
    if (!this._instance) {
      this._instance = new RootStore();
    }

    return this._instance;
  }

  @action
  logout(): void {
    Object.values(this)
      .filter((v) => v instanceof ChildStore)
      .forEach((v) => (v as ChildStore<any>).reset());
    Context.storage.reset();
  }
}
