import { ContextStatic } from "@devslane/mobx-entity-manager";
import { BaseApiService } from "../services/BaseApiService";
import { StorageService } from "../services/StorageService";
import { ToastService } from "../services/ToastService";

export class Context {
  static storage: StorageService;
  static baseUrl: string;
  static toast: ToastService;

  static boot(opts: {
    storageService: StorageService;
    toastService: ToastService;
    baseUrl: string;
  }): void {
    // ContextStatic should be booted before creating instance of RootStore
    ContextStatic.boot({
      api: BaseApiService.getInstance(),
    });

    this.storage = opts.storageService;
    this.baseUrl = opts.baseUrl;
    this.toast = opts.toastService;
  }
}
