import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { StateStore } from "stores/entities/StateStore";

export class State extends EntityBaseModel {
  static _store: StateStore;

  @observable
  state!: string;

  @observable
  code!: string;
}
