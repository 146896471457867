import { observable } from "mobx";
import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { UnitImageStore } from "stores/entities/UnitImageStore";

export class UnitImage extends EntityBaseModel {
  static _store: UnitImageStore;

  @observable unit_id!: number;

  @observable url!: string;
}
