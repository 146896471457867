export const AppRoutes = {
  unitDetailUrl: '/:orgId/unit/:unitId',
  orgUrl: '/:orgId/listings',
};

export const RouteLinks = {
  privacy: "https://www.mymatrixrent.com/",
  terms: "https://www.mymatrixrent.com/",
};

export const GUEST_APPLY = (unitId: number) => `/units/${unitId}/apply`;
